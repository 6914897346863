import React from "react"
import Layout from "../../components/layout/Layout"
import Grid from "../../components/grid/Grid"
import Card from "../../components/card/Card"
import Date from "../../components/date/Date"
import Markdown from "../../components/markdown/Markdown"
import css from "./Day.module.css"

export default ({ pageContext: { url, node, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.day}
      slug={`${url}/${node.slug}`}
      title={node.titulo}
    >
      <section className={css.header}>
        <div className="container">
          <h1 className={css.title}>{node.nombre}</h1>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="text-column">
            <Markdown className={css.contain}>
              {node.presentacion.childMarkdownRemark.html}
            </Markdown>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          {node.eventosAnuales && (
            <>
              <p>LISTA (O GRID) DE LOS EVENTOS PASADOS:</p>
              <div className={css.gridDay}>
                {node.eventosAnuales.map((evento, i) => (
                  <div key={i} className={css.itemGrid}>
                    <p>{evento.anho}</p>
                    <p>{evento.titulo}</p>
                    <img src={evento.afiche.file.url} alt="poster" />
                    <Markdown>
                      {evento.cuerpo.childMarkdownRemark.html}
                    </Markdown>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </section>
      <section className="articles-related">
        <div className="container">
          <h2>Últimas artículos relacionados</h2>
          <Grid>
            {data.articles.slice(0, 4).map((item, i) => (
              <Card url={`/${item.slug}`} key={i}>
                <img src={item.portada?.file.url} alt="img"></img>
                <h3>{item.titulo}</h3>
                <Date date={item.fecha} />
                <p>{item.cuerpo.childMarkdownRemark.excerpt}</p>
              </Card>
            ))}
          </Grid>
        </div>
      </section>
    </Layout>
  )
}
